<template>
  <div id="wizard-view" ref="wizardModal">
    <b-modal
      id="menubar_wizard_modal"
      class="modal-dialog modal-dialog-centered modal-lg"
      scrollable
      ref="my-modal"
      hide-footer
      size="lg"
      style=" z-index: 1500 !important;"
      @show="checkData"
      @close="resetAll"
    >
      <section class="">
        <select-category
          :categoryToOccasion="categoryToOccasion"
          v-if="showCategory"
        ></select-category>
        <select-occasion
          v-bind:menu="menu"
          v-if="showOccasion"
          v-on:ocasionToAddfriend="ocasionToAddfriend"
          v-on:ocasionToAgeRange="ocasionToAgeRange"
          v-on:ocasionToPrice="ocasionToPrice"
          :occasionToCategory="occasionToCategory"
        ></select-occasion>
        <age-range
          v-bind:menu="menu"
          v-if="showAge"
          v-on:agerangeToTrait="agerangeToTrait"
          v-on:ageRangeToGender="ageRangeToGender"
          v-on:ageRangeToAddfriend="ageRangeToAddfriend"
          v-on:ageRangeToOccasion="ageRangeToOccasion"
        ></age-range>
        <select-gender
          v-bind:menu="menu"
          v-if="showGender"
          v-on:genderToAddfriend="genderToAddfriend"
          v-on:genderToAgeRange="genderToAgeRange"
        ></select-gender>
        <select-persona
          v-bind:gender="gender"
          v-bind:menu="menu"
          v-if="showPersona"
          v-on:personaToRelation="personaToRelation"
          v-on:showPersonaToLifestyle="showPersonaToLifestyle"
        ></select-persona>
        <add-friend
          v-bind:menu="menu"
          v-bind:gender="gender"
          v-if="addFriend"
          v-on:checkWizardDetails="checkWizardDetails"
          v-on:addfriendToOccasion="addfriendToOccasion"
        ></add-friend>
        <price-range
          v-bind:menu="menu"
          v-bind:occasion="occasion"
          v-on:getWizardProducts="getWizardProducts"
          v-if="SelectPricerange"
          v-on:closeModal="closeModal"
          v-on:priceToStyle="priceToStyle"
        ></price-range>
        <select-relation
          v-bind:menu="menu"
          v-if="showRelation"
          v-on:relationToPersona="relationToPersona"
          v-on:relationToTrait="relationToTrait"
        ></select-relation>

        <select-style
          v-bind:gender="gender"
          v-bind:menu="menu"
          v-if="showStyle"
          v-on:styleToPersona="styleToPersona"
          v-on:styleToPrice="styleToPrice"
          v-on:underFifty="underFifty"
        ></select-style>

        <select-trait
          v-bind:gender="gender"
          v-bind:age="age"
          v-bind:menu="menu"
          v-if="showTrait"
          v-on:traitToRelation="traitToRelation"
          v-on:traitToAgeRange="traitToAgeRange"
        ></select-trait>

        <select-continue
          v-bind:menu="menu"
          v-if="selctContact"
          v-bind:selected_friend="selected_friend"
          v-on:showPrceRange="showPrceRange"
          v-on:fiftySubmit="fiftySubmit"
          v-on:SelcetContactToAddfriend="SelcetContactToAddfriend"
        ></select-continue>
        <div v-if="!showOccasion" class="px-4 py-1">
          <p class="mb-0">You've Selected</p>
        </div>
        <div class="px-4 mb-4 py-2">
          <strong class="text-dark mt-1" v-if="occasion != null">
            {{ occasion.occasion_name }} |
          </strong>
          <strong class="text-dark mt-1" v-if="selected_friend != null">
            {{ selected_friend.contact_name }} <i class="fa fa-user"></i> |
          </strong>
          <strong class="text-dark mt-1" v-if="gender != null">
            {{ gender }} |
          </strong>
          <strong class="text-dark mt-1" v-if="age != null">
            {{ age.age_label }} |
          </strong>
          <strong class="text-dark mt-1" v-if="caricature_trait != null">
            <img :src="caricature_trait.src" class="avatar-img" alt="" /> |
          </strong>
          <strong class="text-dark mt-1" v-if="relation != null">
            {{ relation.relation_name }} |
          </strong>
          <!-- <strong class="text-dark mt-1" v-if="personas != null"> <img  :src="personas['0'].src" class="avatar-img" alt="">  | </strong> -->
          <!-- <strong class="text-dark mt-1" v-if="active_lifestyles != null"> <img  :src="active_lifestyles['0'].src" class="avatar-img" alt="">    | </strong> -->
          <strong class="text-dark mt-1" v-if="price != null">
            ${{ price.from_price }} To ${{ price.to_price }} |
          </strong>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <strong>
              <center v-if="personas != null">PERSONA</center>
            </strong>
            <div class="remove-img-bg selected-persona-wrapper">
              <vue-select-image
                :dataImages="personas"
                :h="'65px'"
                :w="'100px'"
                :limit="3"
                useLabel
                class="d-flex justify-content-center"
              >
              </vue-select-image>
            </div>
            <!--<div  v-for="persona in personas">
                            <strong class="text-dark ml-3" v-if="personas != null"><img   :src="persona.src" class="avatar-img" alt=""><br><center><span>{{persona.alt}}</span></center>   </strong>
                        </div> -->
          </div>
          <div class="col-md-6">
            <strong
              ><center v-if="active_lifestyles != null">
                ACTIVE LIFESTYLE
              </center></strong
            >
            <div class="remove-img-bg selected-persona-wrapper">
              <vue-select-image
                :dataImages="active_lifestyles"
                :h="'65px'"
                :w="'100px'"
                :limit="3"
                useLabel
                class="d-flex justify-content-center"
              >
              </vue-select-image>
            </div>
            <!-- <div  v-for="style in active_lifestyles" >
                            <strong class="text-dark ml-3" v-if="active_lifestyles != null"><img   :src="style.src" class="avatar-img" alt=""><br><center><span>{{style.alt}}</span>   </center></strong>
                        </div> -->
          </div>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import SelectCategory from "@/views/site/wizard/SelectCategory";
import SelectOccasion from "@/views/site/wizard/SelectOccasion";
import AgeRange from "@/views/site/wizard/AgeRange";
import SelectGender from "@/views/site/wizard/SelectGender";
import SelectPersona from "@/views/site/wizard/SelectPersona";
import AddFriend from "@/views/site/wizard/AddFriend";
import PriceRange from "@/views/site/wizard/PriceRange";
import SelectRelation from "@/views/site/wizard/SelectRelation";
import SelectStyle from "@/views/site/wizard/SelectStyle";
import SelectTrait from "@/views/site/wizard/SelectTrait";
import SelectContinue from "@/views/site/wizard/selectContinue";

import VueSelectImage from "vue-select-image";
require("vue-select-image/dist/vue-select-image.css");

export default {
  name: "WizardView",
  props: ["wizard_data", "modal_id"],
  data() {
    return {
      errors: [],
      error_msg: null,
      loader: false,
      menu: null,
      occasion: null,
      age: null,
      gender: null,
      personas: null,
      price: null,
      relation: null,
      priceRange_from_price: null,
      priceRange_to_price: null,
      active_lifestyles: null,
      caricature_trait: null,
      selected_friend: null,
      persona_array: null,
      lifestyle_array: null,
      wizard_Details: null,
      showAge: false,
      showCategory: false,
      showOccasion: false,
      showGender: false,
      showPersona: false,
      addFriend: false,
      SelectPricerange: false,
      showRelation: false,
      toggleModal: false,
      showStyle: false,
      showTrait: false,
      selctContact: false,
    };
  },
  components: {
    SelectCategory,
    SelectOccasion,
    AgeRange,
    SelectGender,
    SelectPersona,
    AddFriend,
    PriceRange,
    SelectRelation,
    SelectStyle,
    SelectTrait,
    SelectContinue,
    VueSelectImage,
  },
  methods: {
    checkData() {
      if (this.$store.state.wizData && this.$route.name === "ProductView") {
        this.occasion = this.$store.state.wizData.occasion;
        this.age = this.$store.state.wizData.age;
        this.gender = this.$store.state.wizData.gender;
        this.personas = this.$store.state.wizData.personas;
        this.relation = this.$store.state.wizData.relation;
        this.price = this.$store.state.wizData.price;
        this.active_lifestyles = this.$store.state.wizData.active_lifestyles;
        this.caricature_trait = this.$store.state.wizData.caricature_trait;
        this.selected_friend = this.$store.state.wizData.selected_friend;
        this.menu = this.$store.state.wizData.menu;
        this.SelectPricerange = true;
      } else {
        this.resetAll();
        this.showCategory = true;
      }
    },
    getWizardProducts(selected_price) {
      const wizardUsage = localStorage.getItem("wizardUsage") ?? 0;
      localStorage.setItem("wizardUsage", Number(wizardUsage) + 1);
      this.loader = true;
      this.price = selected_price;
      // this.selected_friend = selected_friend;
      // localStorage.setItem("selcetedFriend", JSON.stringify(selected_friend));
      // this.loader = true;
      if (this.menu.persona_status) {
        this.getPersonaArray();
      }
      if (this.menu.active_lifestatus) {
        this.getLifeStyleArray();
      }
      // if (this.occasion.special_occasion != "True") {
      if (this.gender == "Male") {
        var searchText = this.age.male_search_text;
      } else {
        var searchText = this.age.female_search_text;
      }
      // }
      if (this.price != null) {
        var priceRange_from_price = this.price.from_price;
        var priceRange_to_price = this.price.to_price;
      } else {
        var priceRange_from_price = null;
        var priceRange_to_price = null;
      }
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = null;
      if (userdata_array) {
        var api_token = userdata_array.token;
        user_id = userdata_array.user.id;
      }
      var contactId = null;
      if (this.selected_friend) {
        contactId = this.selected_friend.id;
      }
      var country_code = localStorage.getItem("countryCode");
      var countryCode = JSON.parse(country_code);
      var fetch_url =
        process.env.VUE_APP_URL + "customer/products/getWizardProducts";
      if (this.occasion.special_occasion != "True") {
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            // Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            occasion: this.occasion.id,
            ageGroup: this.menu.label,
            ageRange: this.age.id,
            searchText: searchText,
            gender: this.gender,
            priceRange_from_price: priceRange_from_price,
            priceRange_to_price: priceRange_to_price,
            characterTrait: this.caricature_trait.id,
            activeLifeStyle: this.lifestyle_array,
            persona: this.persona_array,
            relation: this.relation.id,
            user_id: user_id,
            contact_id: contactId,
            countryCode: countryCode,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            var products = data.response;
            const id = Math.random()
              .toString(36)
              .slice(2);

            this.$store.state.wizardProducts_items = data.response.items;
            this.$store.state.wizardProducts_suggested_items =
              data.response.suggested_items;
            if (this.selected_friend) {
              this.$store.state.user_contact = this.selected_friend.id;
              this.$store.state.user_contact_name = this.selected_friend.contact_name;
            }
            if (data.wizard_detail) {
              this.$store.state.clicked_wizarddata = data.wizard_detail;
            }
            this.$store.state.menu = this.menu;
            this.$store.state.occasion_id = this.occasion.id;
            this.$store.state.characterTrait = this.caricature_trait;
            this.$store.state.totalPages = data.response.totalPages;
            this.$store.state.request_body = data.request_body;
            this.$store.state.wizard_type = "User";
            this.$store.state.pet_id = null;
            this.$store.state.page = 1;
            const wizData = {
              occasion: this.occasion,
              age: this.age,
              gender: this.gender,
              personas: this.personas,
              relation: this.relation,
              price: this.price,
              active_lifestyles: this.active_lifestyles,
              caricature_trait: this.caricature_trait,
              selected_friend: this.selected_friend,
              menu: this.menu,
            };
            this.$store.state.wizData = wizData;
            this.$store.state.wizardData = {
              occasion: this.occasion.id,
              ageGroup: this.menu.label,
              ageRange: this.age.id,
              searchText: searchText,
              gender: this.gender,
              priceRange_from_price: priceRange_from_price,
              priceRange_to_price: priceRange_to_price,
              characterTrait: this.caricature_trait.id,
              activeLifeStyle: this.lifestyle_array,
              persona: this.persona_array,
              relation: this.relation.id,
              user_id: user_id,
              contact_id: contactId,
              countryCode: countryCode,
              menu: this.menu,
            };
            this.$router.replace({
              name: "ProductView",
              params: {
                id,
              },
            });
            this.closeModal();
          })
          .catch(function(err) {
            console.log("Fetch Error :-S", err);
          });
      } else {
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            occasion: this.occasion.id,
            ageGroup: this.menu.label,
            ageRange: this.age.id,
            searchText: searchText,
            gender: this.gender,
            priceRange_from_price: priceRange_from_price,
            priceRange_to_price: priceRange_to_price,
            characterTrait: this.caricature_trait.id,
            activeLifeStyle: this.lifestyle_array,
            persona: this.persona_array,
            relation: this.relation.id,
            user_id: user_id,
            contact_id: contactId,
            countryCode: countryCode,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            var products = data.response;

            const id = Math.random()
              .toString(36)
              .slice(2);
            this.$store.state.wizardProducts_items = data.response.items;
            this.$store.state.wizardProducts_suggested_items =
              data.response.suggested_items;
            if (this.selected_friend) {
              this.$store.state.user_contact = this.selected_friend.id;
              this.$store.state.user_contact_name = this.selected_friend.contact_name;
            }

            this.$store.state.occasion_id = this.occasion.id;
            this.$store.state.characterTrait = this.caricature_trait;
            this.$store.state.request_body = data.request_body;
            this.$store.state.totalPages = data.response.totalPages;
            this.$store.state.wizard_type = "User";
            this.$store.state.pet_id = null;
            const wizData = {
              occasion: this.occasion,
              age: this.age,
              gender: this.gender,
              personas: this.personas,
              relation: this.relation,
              price: this.price,
              active_lifestyles: this.active_lifestyles,
              caricature_trait: this.caricature_trait,
              selected_friend: this.selected_friend,
              menu: this.menu,
            };
            this.$store.state.wizardData = {
              occasion: this.occasion.id,
              ageGroup: this.menu.label,
              ageRange: this.age.id,
              searchText: searchText,
              gender: this.gender,
              characterTrait: this.caricature_trait.id,
              activeLifeStyle: this.lifestyle_array,
              persona: this.persona_array,
              relation: this.relation.id,
              user_id: user_id,
              contact_id: contactId,
              countryCode: countryCode,
              menu: this.menu,
            };
            this.$store.state.wizData = wizData;
            this.$store.state.page = 1;
            this.$router.replace({
              name: "ProductView",
              params: {
                id,
                // wizardProducts_items: data.response.items,
                // wizardProducts_suggested_items: data.response.suggested_items,
                // user_contact: this.selected_friend.id,
                // user_contact_name: this.selected_friend.contact_name,
                // clicked_wizarddata: data.wizard_detail,
                // occasion_id: this.occasion.id,
                // characterTrait: this.caricature_trait,
              },
            });
            this.closeModal();
          })
          .catch(function(err) {
            console.log("Fetch Error :-S", err);
          });
      }
    },
    setWizardDetails() {},
    getPersonaArray() {
      var img_array = new Array();

      this.personas.forEach(function(persona) {
        img_array.push(persona.id);
      });
      this.persona_array = img_array;
    },
    getLifeStyleArray() {
      var img_array = new Array();

      this.active_lifestyles.forEach(function(style) {
        img_array.push(style.id);
      });

      this.lifestyle_array = img_array;
    },
    addfriendToOccasion() {
      // this.caricature_trait = null;
      this.showOccasion = true;
      this.addFriend = false;
    },
    occasionToCategory() {
      this.showOccasion = false;
      this.showCategory = true;
    },
    categoryToOccasion(menu) {
      this.menu = menu;
      if (menu.wizard_decrypt) {
        if (menu.wizard_decrypt.gender != "Both") {
          this.gender = this.menu.wizard_decrypt.gender;
        }
      }
      this.showOccasion = true;
      this.showCategory = false;
    },
    ocasionToAddfriend(occasion) {
      this.occasion = occasion;
      this.showOccasion = false;
      this.addFriend = true;
    },
    ocasionToPrice(occasion) {
      this.occasion = occasion;
      this.showOccasion = false;
      this.SelectPricerange = true;
    },
    addfriendToGender() {
      // this.selected_friend = selected_friend;
      this.addFriend = false;
      this.showGender = true;
    },
    ocasionToAgeRange(occasion) {
      this.occasion = occasion;
      this.showOccasion = false;
      this.showAge = true;
    },
    addfriendAgerange() {
      // this.selected_friend = selected_friend;
      this.addFriend = false;
      this.showAge = true;
    },
    ageRangeToOccasion() {
      this.showAge = false;
      this.showOccasion = true;
    },
    ageRangeToGender() {
      // this.occasion = age;
      this.showAge = false;
      this.showGender = true;
    },
    ageRangeToAddfriend() {
      this.showAge = false;
      this.addFriend = true;
    },
    genderToAgeRange(gender) {
      this.gender = gender;
      this.showGender = false;
      this.showAge = true;
    },
    genderToAddfriend() {
      // this.gender = gender;
      this.showGender = false;
      this.addFriend = true;
    },
    agerangeToTrait(age) {
      this.age = age;
      this.showAge = false;
      this.showTrait = true;
    },
    traitToAgeRange() {
      // this.active_lifestyle = null;
      this.showAge = true;
      this.showTrait = false;
    },
    traitToRelation(caricature_trait) {
      this.caricature_trait = caricature_trait;
      this.showTrait = false;
      this.showRelation = true;
    },
    relationToTrait() {
      // this.occasion = null;
      this.showTrait = true;
      this.showRelation = false;
    },
    relationToPersona(relation) {
      this.relation = relation;
      if (this.menu == "under_fifty") {
        this.showRelation = false;
        this.showPersona = true;
      } else {
        if (this.menu.persona_status) {
          this.showRelation = false;
          this.showPersona = true;
        } else {
          this.showRelation = false;
          this.showPersonaToLifestyle(null);
        }
      }
    },
    personaToRelation() {
      // this.gender = null;
      this.showPersona = false;
      this.showRelation = true;
    },
    showPersonaToLifestyle(persona) {
      this.personas = persona;
      if (this.menu == "under_fifty") {
        this.showPersona = false;
        this.showStyle = true;
      } else {
        if (this.menu.active_lifestatus) {
          this.showPersona = false;
          this.showStyle = true;
        } else {
          this.showPersona = false;
          this.styleToPrice(null);
        }
      }
    },
    styleToPersona() {
      // this.relation = null;
      if (this.menu.persona_status) {
        this.showPersona = true;
        this.showStyle = false;
      } else {
        this.showStyle = false;
        this.personaToRelation(null);
      }
    },
    styleToPrice(active_lifestyle) {
      this.active_lifestyles = active_lifestyle;
      this.showStyle = false;
      this.SelectPricerange = true;
    },
    priceToStyle() {
      // this.persona = null;
      if (this.menu.active_lifestatus) {
        this.SelectPricerange = false;
        this.showStyle = true;
      } else {
        this.SelectPricerange = false;
        this.styleToPersona();
      }
    },
    fiftySubmit(style) {
      if (style) {
        this.active_lifestyles = style;
      }
      // this.selctContact = false;
      this.age = this.wizard_Details.ageRange;
      this.gender = this.wizard_Details.gender;
      var persona_array = new Array();
      this.wizard_Details.persona.forEach(function(personas) {
        persona_array.push({
          id: personas.id,
          src: personas.icon_persona,
          alt: "",
        });
      });

      this.personas = persona_array;
      this.relation = this.wizard_Details.relation;
      var style_array = new Array();
      this.wizard_Details.activeLifeStyle.forEach(function(style) {
        style_array.push({
          id: style.id,
          src: style.icon_style,
          alt: "",
        });
      });
      this.active_lifestyles = style_array;
      this.caricature_trait = {
        id: this.wizard_Details.characterTrait.id,
        src: this.wizard_Details.characterTrait.icon_name,
        alt: "",
      };
      this.underFifty();
    },
    showPrceRange() {
      this.selctContact = false;
      this.SelectPricerange = true;
      this.age = this.wizard_Details.ageRange;
      this.gender = this.wizard_Details.gender;
      var persona_array = new Array();
      this.wizard_Details.persona.forEach(function(personas) {
        persona_array.push({
          id: personas.id,
          src: personas.icon_persona,
          alt: personas.name,
        });
      });

      this.personas = persona_array;
      this.relation = this.wizard_Details.relation;
      var style_array = new Array();
      this.wizard_Details.activeLifeStyle.forEach(function(style) {
        style_array.push({
          id: style.id,
          src: style.icon_style,
          alt: style.name,
        });
      });

      this.active_lifestyles = style_array;
      this.caricature_trait = {
        id: this.wizard_Details.characterTrait.id,
        src: this.wizard_Details.characterTrait.icon_name,
        alt: "",
      };
      // this.caricature_trait = trait_array;
    },
    selectContact() {
      this.addFriend = false;
      this.selctContact = true;
    },
    checkWizardDetails(selected_friend) {
      this.selected_friend = selected_friend;
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/wizard/getWizardDetails";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: userdata_array.user.id,
          contact_id: this.selected_friend.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.wizardDetails != null) {
            if (
              data.wizardDetails.persona != null &&
              data.wizardDetails.activeLifeStyle != null &&
              data.wizardDetails.characterTrait != null
            ) {
              this.wizard_Details = data.wizardDetails;
              this.selectContact();
            } else {
              if (this.menu.wizard_decrypt.gender == "Both") {
                this.addfriendToGender();
              } else {
                this.addfriendAgerange();
              }
            }
          } else if (
            this.menu == "under_fifty" &&
            this.selected_friend.gender == null
          ) {
            this.addfriendToGender();
          } else if (this.selected_friend.gender != null) {
            this.gender = this.selected_friend.gender;
            this.addfriendAgerange();
          } else {
            if (this.menu.wizard_decrypt.gender == "Both") {
              this.addfriendToGender();
            } else {
              // this.gender = this.selected_friend.gender;
              this.addfriendAgerange();
            }
          }
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    SelcetContactToAddfriend() {
      this.addFriend = true;
      this.selctContact = false;
    },
    underFifty(active_lifestyle) {
      this.loader = true;
      if (active_lifestyle) {
        this.active_lifestyles = active_lifestyle;
      }
      // this.loader = true;
      this.getPersonaArray();
      this.getLifeStyleArray();
      if (this.gender == "Male") {
        var searchText = this.age.male_search_text;
      } else {
        var searchText = this.age.female_search_text;
      }
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var fetch_url = process.env.VUE_APP_URL + "customer/wizard/underFifty";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          occasion: this.occasion.id,
          ageGroup: this.menu.label,
          ageRange: this.age.id,
          searchText: searchText,
          gender: this.gender,
          characterTrait: this.caricature_trait.id,
          activeLifeStyle: this.lifestyle_array,
          persona: this.persona_array,
          relation: this.relation.id,
          user_id: userdata_array.user.id,
          contact_id: this.selected_friend.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          var products = data.response;

          const id = Math.random()
            .toString(36)
            .slice(2);

          this.$store.state.wizardProducts_items = data.response.items;
          this.$store.state.wizardProducts_suggested_items =
            data.response.suggested_items;
          this.$store.state.user_contact = this.selected_friend.id;
          this.$store.state.user_contact_name = this.selected_friend.contact_name;
          this.$store.state.occasion_id = this.occasion.id;
          this.$store.state.characterTrait = this.caricature_trait;
          this.$store.state.totalPages = data.response.totalPages;
          this.$store.state.request_body = data.request_body;
          this.$store.state.page = 1;
          const wizData = {
            occasion: this.occasion,
            age: this.age,
            gender: this.gender,
            personas: this.personas,
            relation: this.relation,
            price: this.price,
            active_lifestyles: this.active_lifestyles,
            caricature_trait: this.caricature_trait,
            selected_friend: this.selected_friend,
            menu: this.menu,
          };
          this.$store.state.wizardData = {
            occasion: this.occasion.id,
            ageGroup: this.menu.label,
            ageRange: this.age.id,
            searchText: searchText,
            gender: this.gender,
            characterTrait: this.caricature_trait.id,
            activeLifeStyle: this.lifestyle_array,
            persona: this.persona_array,
            relation: this.relation.id,
            user_id: userdata_array.user.id,
            contact_id: this.selected_friend.id,
            menu: this.menu,
          };
          this.$store.state.clicked_wizarddata = wizData;

          this.$router.replace({
            name: "ProductView",
            params: {
              id,
            },
          });
          this.closeModal();
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    closeModal() {
      this.$bvModal.hide("menubar_wizard_modal");
    },
    resetAll() {
      this.occasion = null;
      this.age = null;
      // this.gender = null;
      this.personas = null;
      this.relation = null;
      this.price = null;
      this.active_lifestyles = null;
      this.caricature_trait = null;
      this.selected_friend = null;
      this.showAge = false;
      this.showOccasion = false;
      this.showGender = false;
      this.showPersona = false;
      this.addFriend = false;
      this.SelectPricerange = false;
      this.showRelation = false;
      this.showStyle = false;
      this.showTrait = false;
      this.selctContact = false;
    },
  },
};
</script>

<style>
.remove-img-bg .vue-select-image__thumbnail--selected {
  background: none !important;
}

.remove-img-bg .vue-select-image__thumbnail {
  cursor: initial;
}
</style>
