<template>
  <div>
    <nav class="navbar">
      <div class="mobile">
        <router-link
          to="/home"
          href="#"
          title="Govava Gift Store"
          class="mobile-image-wrapper"
        >
          <img
            src="@/assets/site/images/govava-logo-new.png"
            alt="Govava logo"
            class="logo"
          />
        </router-link>
        <div @click="handleToggleMenu()" class="toggle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 12H18"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M5 17H11"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M5 7H15"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
      <div class="upper" :class="toggleMenu && 'active'">
        <router-link to="/home" href="#" title="Govava Gift Store">
          <img
            src="@/assets/site/images/govava-logo-new.png"
            alt="Govava logo"
            class="logo"
          />
        </router-link>
        <p v-if="path === '/'" class="gift-text big">Find The Perfect Gift</p>
        <p v-else class="gift-text">Find The Perfect Gift <sup>TM</sup></p>
        <div class="right">
          <div v-if="userData" class="auth">
            <div ref="navbarAuth" class="profile">
              <div @click="handleToggleAuth()" class="account">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                    stroke="#666666"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                    stroke="#666666"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {{ userName }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z"
                    fill="#666666"
                  />
                </svg>
              </div>
              <div
                class="auth-links"
                @click="handleToggleAuth()"
                :class="toggleAuth && 'active'"
              >
                <router-link to="reset_password" href="#"
                  >Reset Password</router-link
                >
                <router-link to="/profile" href="#">Edit Profile</router-link>
                <router-link to="/contact" href="#">Edit Contacts</router-link>
                <router-link to="/pets_view" href="#">Edit Pets</router-link>
                <span @click="logout" class="logout">Logout</span>
              </div>
            </div>
          </div>
          <div v-else>
            <router-link to="/login" class="login-link">
              Login
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2.00098 11.999L16.001 11.999M16.001 11.999L12.501 8.99902M16.001 11.999L12.501 14.999"
                  stroke="#1C274C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.00195 7C9.01406 4.82497 9.11051 3.64706 9.87889 2.87868C10.7576 2 12.1718 2 15.0002 2L16.0002 2C18.8286 2 20.2429 2 21.1215 2.87868C22.0002 3.75736 22.0002 5.17157 22.0002 8L22.0002 16C22.0002 18.8284 22.0002 20.2426 21.1215 21.1213C20.3531 21.8897 19.1752 21.9862 17 21.9983M9.00195 17C9.01406 19.175 9.11051 20.3529 9.87889 21.1213C10.5202 21.7626 11.4467 21.9359 13 21.9827"
                  stroke="#1C274C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </router-link>
          </div>
        </div>
        <div class="search">
          <input
            id="searchbox"
            @keyup.enter="keword_search"
            type="text"
            name="q"
            v-model="search_word"
            placeholder="Search..."
            class="input-text input-searchbox"
            maxlength="128"
            role="combobox"
            aria-haspopup="false"
            aria-expanded="true"
            aria-autocomplete="both"
            autocomplete="off"
          />
          <div type="button" title="Search" @click="keword_search" class="icon">
            <button
              v-if="search_load"
              type="button"
              class="buttonload navbar-search-spinner"
              disabled="disabled"
            >
              <i class="fa fa-refresh fa-spin"></i>
            </button>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 10.5C15 12.9853 12.9853 15 10.5 15C8.01472 15 6 12.9853 6 10.5C6 8.01472 8.01472 6 10.5 6C12.9853 6 15 8.01472 15 10.5ZM14.1793 15.2399C13.1632 16.0297 11.8865 16.5 10.5 16.5C7.18629 16.5 4.5 13.8137 4.5 10.5C4.5 7.18629 7.18629 4.5 10.5 4.5C13.8137 4.5 16.5 7.18629 16.5 10.5C16.5 11.8865 16.0297 13.1632 15.2399 14.1792L20.0304 18.9697L18.9697 20.0303L14.1793 15.2399Z"
                fill="#ffffff"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="lower" :class="toggleMenu && 'active'">
        <div v-if="userData" class="lower-left">
          <router-link to="/compare_view" class="link">
            <div class="compare-icon"></div>
            Compare
          </router-link>
          <router-link to="/favourite_view" class="link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
                fill="#FE3C00"
              />
            </svg>
            Favorites
          </router-link>
        </div>
        <div class="lower-center">
          <router-link to="/home">
            Home
          </router-link>
          <a @click="showPetWizard(pet)"
            >Pets <span class="blue">AI</span> Wizard</a
          >
          <router-link to="/browse">
            Browse
          </router-link>
        </div>
        <div v-if="userData" class="lower-right">
          <span @click="giftCard_search()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="#ffd700"
              viewBox="0 0 512 512"
              version="1.1"
              xml:space="preserve"
            >
              <g id="gift_box-box_-heart-love-valentine">
                <path
                  d="M408,160h-64c15.55-0.021,28.483-12.719,28.504-28.269c0.021-15.55-12.568-28.139-28.118-28.118   c0.023-17.486-15.9-31.228-34.048-27.504C297.124,78.82,288,91.085,288,104.575v5.667c-4.256-3.838-9.831-6.242-16-6.242h-32   c-6.169,0-11.744,2.404-16,6.242v-5.667c0-13.491-9.124-25.755-22.339-28.467c-18.148-3.724-34.071,10.018-34.048,27.504   c-15.549-0.021-28.138,12.568-28.118,28.118C139.517,147.281,152.45,159.979,168,160h-64c-17.673,0-32,14.327-32,32v8   c0,17.673,14.327,32,32,32h96v16H96v161.28c0,16.966,13.754,30.72,30.72,30.72H200c8.837,0,16-7.163,16-16V168h80v256   c0,8.837,7.163,16,16,16h73.28c16.966,0,30.72-13.754,30.72-30.72V248H312v-16h96c17.673,0,32-14.327,32-32v-8   C440,174.327,425.673,160,408,160z M232,152v-24c0-4.41,3.586-8,8-8h32c4.414,0,8,3.59,8,8v24H232z"
                />
              </g>
              <g id="Layer_1" />
            </svg>
            Gift Cards
          </span>
          <router-link to="/giftee_list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.89 5.87891H5.11C3.4 5.87891 2 7.27891 2 8.98891V20.3489C2 21.7989 3.04 22.4189 4.31 21.7089L8.24 19.5189C8.66 19.2889 9.34 19.2889 9.75 19.5189L13.68 21.7089C14.96 22.4089 16 21.7989 16 20.3489V8.98891C16 7.27891 14.6 5.87891 12.89 5.87891Z"
                fill="#ffd700"
              />
              <path
                d="M21.9998 5.11V16.47C21.9998 17.92 20.9598 18.53 19.6898 17.83L17.7598 16.75C17.5998 16.66 17.4998 16.49 17.4998 16.31V8.99C17.4998 6.45 15.4298 4.38 12.8898 4.38H8.81984C8.44984 4.38 8.18984 3.99 8.35984 3.67C8.87984 2.68 9.91984 2 11.1098 2H18.8898C20.5998 2 21.9998 3.4 21.9998 5.11Z"
                fill="#ffd700"
              />
            </svg>
            Saved Giftees
          </router-link>
          <router-link to="/wishlist">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
                fill="#38afdf"
              />
            </svg>
            Wishlist
          </router-link>
        </div>
      </div>
      <petwizard-view v-bind:menu="menu"></petwizard-view>
      <div class="gradient-line"></div>
    </nav>
    <div
      id="myModal"
      ref="modal"
      class="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content p-5 custom-modal">
          <div class="row d-flex justify-content-end">
            <div class="col-1 cursor-pointer" @click="closeloginModal">
              <img
                src="@/assets/site/images/close-icon.svg"
                width="40"
                height="40"
                class="img-fluid modal-close"
                alt=""
              />
            </div>
          </div>

          <div class="row text-center">
            <div class="col-md-6 my-3">
              <img
                src="@/assets/site/images/new/banner.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="row mt-4 d-flex justify-content-center">
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/laptop.png"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/shoes.jpg"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/pendant.jpg"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/smart-watch.webp"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div
                    class="
                          category-holder1
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                  >
                    <img
                      src="@/assets/site/images/new/watch.webp"
                      width="40"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h3 class="py-5 modal-head">FIND THE PERFECT GIFT</h3>
            </div>
            <div class="col-md-6 my-3">
              <h3>
                <b>Unlock Govava True Power</b>
              </h3>

              <!-- <p class="my-4 text-left" style="font-size: 14px">
                    When you sign-up, you will be in on a Gift buying journey
                    unlike anything you have EVER experienced
                  </p> -->

              <p class="my-4 text-left" style="font-size: 14px">
                Sign up to embark on a unique gifting experience unlike any
                other.
              </p>
              <!-- <router-link to="/login" > -->
              <button class="btn btn-info1 my-4">
                <a href="/login">Sign Up</a>
              </button>
              <!-- </router-link> -->

              <ul id="modal_ul" class="my-4 text-left">
                <li>Access to over 8000+ Retail stores</li>
                <li>Valid coupons on almost every item instantly</li>
                <li>No more guessing when trying to find gifts</li>
                <li>Shopping for gifts for your pets</li>
                <li>Gift-Chat in real time using the app</li>
                <li>Save Gifts for family and friends in Gift-Cart</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WizardView from "@/views/site/wizard/WizardView";
import PetwizardView from "@/views/site/petwizard/PetwizardView";
import SearchView from "@/views/site/blocks/SearchView";
import { VueAutosuggest } from "vue-autosuggest";
import axios from "axios";

export default {
  name: "HomeBar",
  data() {
    return {
      toggleMenu: false,
      toggleAuth: false,
      homeview: true,
      userData: null,
      userName: null,
      menu: null,
      menus: [],
      gender: null,
      browsemenus: [],
      categories: [],
      cat: "",
      pet: "cat",
      search: null,
      search_keywords: null,
      search_word: null,
      selected_category: "",
      search_load: false,
      wizard_data: null,
      modal_id: null,
      search_options: [],
      search_result: [],
      selected: "",
      query: "",
      menus_datas: null,
      suggestions: [
        {
          data: [],
        },
      ],
      path: "/",
    };
  },
  components: {
    WizardView,
    PetwizardView,
    SearchView,
    VueAutosuggest,
  },
  created() {
    if (this.$store.state.clicked_wizarddata) {
      this.wizard_data = $store.state.clicked_wizarddata;
    }
    // this.checkUrl();
    this.setUserDatas();
    this.getMenuLists();
    this.getCategoryList();
    this.path = window.location.pathname;
    // this.getBrowsmenus();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  computed: {
    filteredOptions() {
      return [
        {
          data: this.suggestions[0].data.filter((option) => {
            return (
              option.search_title
                .toLowerCase()
                .indexOf(this.query.toLowerCase()) > -1
            );
          }),
        },
      ];
    },
  },
  methods: {
    closeloginModal() {
      $("#myModal").modal("hide");
    },
    handleClickOutside(event) {
      if (
        this.$refs.navbarAuth &&
        !this.$refs.navbarAuth.contains(event.target) &&
        this.toggleAuth
      ) {
        this.toggleAuth = false;
      }
    },
    handleToggleAuth() {
      this.toggleAuth = !this.toggleAuth;
    },
    handleToggleMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    checkUrl() {
      var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
      var segment_array = segment_str.split("/");
      var last_segment = segment_array.pop();
    },
    getMenuLists() {
      var fetch_url = process.env.VUE_APP_URL + "customer/menu/menuList";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.menus = data.menus;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getCategoryList() {
      var fetch_url =
        process.env.VUE_APP_URL + "customer/category/categoryList";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.categories = data.categories;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    keword_search() {
      this.toggleMenu = false;
      this.$store.state.pet_id = null;
      this.$store.state.occasion_id = null;
      this.search_load = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          keyword: this.search_word,
          type: "keyword",
          category: this.selected_category,
          page: 1,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          //  this.loader = false;
          var products = data.response;

          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.keyword = this.search_word;
          this.$store.state.category = this.selected_category;
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.TotalPages;
          this.$store.state.page = 1;
          this.$router.replace({
            name: "SearchView",
            params: {
              id,
            },
          });
          // this.search_word = null;
          // this.selected_category = '';
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    giftCard_search() {
      this.$store.state.pet_id = null;
      this.$store.state.occasion_id = null;
      this.search_load = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          keyword: "gift card",
          type: "gift_card",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          //  this.loader = false;
          var products = data.response;

          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.keyword = "gift card";
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.TotalPages;
          this.$store.state.page = 1;
          this.$router.replace({
            name: "SearchView",
            params: {
              id,
            },
          });
          // this.search_word = null;
          // this.selected_category = '';
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getBrowsmenus() {
      var fetch_url =
        process.env.VUE_APP_URL + "customer/browsemenu/getBrowseMenu";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.browsemenus = data.browsemenu;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    setUserDatas() {
      var loc_UserData = localStorage.getItem("userData");
      // if (loc_UserData.user.name == null) {
      //         localStorage.clear();
      //         alert("Please log in to continue");
      // }
      // console.log(loc_UserData);
      if (loc_UserData) {
        this.userData = JSON.parse(loc_UserData);
        if (this.userData.user.name) {
          this.userName = this.userData.user.name;
        }
      }
    },
    showWizard(menu) {
      var loc_UserData = localStorage.getItem("userData");
      // if (loc_UserData == null) {
      //   alert("Please log in to continue");
      // } else {
      this.menu = menu;
      // console.log(type);
      if (menu.wizard_decrypt) {
        if (menu.wizard_decrypt.gender != "Both") {
          this.gender = this.menu.wizard_decrypt.gender;
        }
      }
      this.modal_id = "menubar_wizard_modal";
      this.$bvModal.show("menubar_wizard_modal");
      // }
    },
    showPetWizard(pet) {
      var loc_UserData = localStorage.getItem("userData");
      this.$store.state.clicked_wizarddata = null;
      this.wizard_data = null;
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        this.pet = pet;
        // console.log(type);
        this.$bvModal.show("petwizard-modal");
      }
    },
    logout() {
      localStorage.clear();
      window.location.href = "/home";
    },
    resetModal() {},
    showBrowseProduct(browsemenu_id) {
      this.errors = [];
      //this.loader = true;
      // var token = localStorage.getItem("userData");
      // var userdata_array = JSON.parse(token);
      // var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL +
        "customer/products/getBrowseProductsV2/" +
        browsemenu_id;
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];

          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$router.replace({
            name: "BrowseView",
            params: {
              id,
              browseProducts: data.response,
            },
          });
        });
    },
    onInputChange(text) {
      this.search = text;
      this.getMenuList(this.search);
    },
    getMenuList(text) {
      let ob = this;
      // this.type = type;
      axios
        .post(
          "https://search-elastic-govava-backend-dvbuninzcno7g3a7u6aj7dd6mm.us-east-1.es.amazonaws.com/keywords/_search",
          {
            // query: {
            //   wildcard: {
            //     search_title: {
            //       value: text + "*",
            //     },
            //   },
            // },
            query: {
              match: {
                search_title: {
                  query: text + "*",
                  operator: "and",
                  fuzziness: 5,
                },
              },
            },
          },
          {
            auth: {
              username: "elastic",
              password: "Elastic123#",
            },
          }
        )
        .then(function(response) {
          let items = response.data.hits;
          // console.log(JSON.stringify(items.hits));

          if (items.hits.length > 0) {
            ob.search_options = [];
            ob.search_result = [];
            //  alert(items.hits.length);

            items.hits.forEach((menu_data, key) => {
              // alert("g");
              ob.search_options.push(menu_data._source);
              ob.search_result.push(menu_data._source);
              // console.log(menu_data._source.search_title);
            });
            ob.suggestions[0].data = ob.search_options;
            ob.video_details = data;
            ob.vid_loader = false;
          } else {
            ob.search_options = [];
            ob.search_result = [];
            ob.suggestions[0].data = ob.search_options;
          }
        });
      // fetch(fetch_api, {
      //     headers: {
      //     "Content-Type": "application/json;charset=UTF-8",
      //     Authorization: "bearer " + this.brearer_token,
      //     Accept: "application/vnd.vimeo.*+json;version=3.4",
      //     },
      // })
      //     .then((res) => res.json())
      //     .then((data) => {
      // this.videos = data["data"];
      // this.search_options = [];
      // data["data"].forEach((video_data, key) => {
      //     this.search_options.push(video_data.name);
      // });
      // this.suggestions[0].data = this.search_options;
      // this.video_details = data;
      // this.vid_loader = false;
      // })
      // .catch(function (err) {
      // console.log("Fetch Error :-S", err);
      // });
    },
    onSelected(item) {
      if (item == null) {
        return;
      }
      this.search_keywords = item.item.keyword;
      this.search_word = item.item.search_title;
      console.log(item.item.search_title);
      this.keword_search_auto();
    },
    keword_search_auto() {
      this.showModal();
      this.toggleMenu = false;
      this.$store.state.pet_id = null;
      this.$store.state.occasion_id = null;
      this.search_load = true;
      var keyword = this.search_result.find((e) => e.keyword);
      if (this.search_keywords != null) {
        var keywords = this.search_keywords;
      } else {
        var keywords = this.search_word;
      }
      // this.search_keywords = keyword.keyword;
      // console.log("search keyword " + this.search_keywords);
      // var category = this.search_result.find(
      //   (e) => e.search_title === this.search
      // );
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          keyword: keywords,
          // type: "keyword",
          // category: category.category,
          page: 1,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          //  this.loader = false;
          var products = data.response;

          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.keyword = keywords;
          this.$store.state.category = this.selected_category;
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.TotalPages;
          this.$store.state.page = 1;
          this.$router.replace({
            name: "SearchView",
            params: {
              id,
            },
          });
          this.hideModal();
          this.search_keywords = null;
          this.search_word = null;
          // this.search_word = null;
          // this.selected_category = '';
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    showModal() {
      this.$refs["modal-loader"].show();
    },
    hideModal() {
      this.$refs["modal-loader"].hide();
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.search_title;
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == "/breast_pumps") {
        this.hideTopbar();
      }
      this.path = to.path;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.navbar-search-spinner {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  opacity: 0.75 !important;
}

/* Navbar Styles */
.navbar {
  padding: 16px 0;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  font-size: 20px;
}

.navbar a {
  font-size: inherit;
}

.navbar .gradient-line {
  height: 12px;
  width: 100%;
  background: linear-gradient(to right, #c97410, #ffd700, #c97410);
}

/* Navbar Mobile Area Styles */
.navbar .mobile {
  padding: 0 64px;
  display: none;
  align-items: center;
  justify-content: space-between;
}

.navbar .mobile .mobile-image-wrapper {
  height: 60px;
  flex-shrink: 0;
}

.navbar .mobile .mobile-image-wrapper img {
  height: 100%;
  width: 100%;
  max-width: 100% !important;
}

.navbar .mobile .toggle {
  cursor: pointer;
}

.navbar .mobile .toggle svg {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 1280px) {
  .navbar .mobile {
    padding: 0 16px;
  }
}

/* Navbar Upper Area Styles */
.navbar .upper {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 20% 1fr 20%;
  gap: 8px;
  padding: 0 64px;
}

.navbar .upper .logo {
  margin: auto;
  margin-right: 0;
  height: 72px;
}

.navbar .upper .gift-text {
  font-family: "Pacifico", cursive;
  margin: auto;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  color: #e24616;
}

.navbar .upper .gift-text.big {
  font-size: 40px;
  font-weight: 500;
}

.navbar .upper .gift-text sup {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  top: -20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  color: #3b3b3b;
}

.navbar .upper .search {
  width: 100%;
  margin: auto;
  grid-column: span 3;
  max-width: 800px;
  height: 40px;
  display: flex;
  font-size: 16px;
}

.navbar .upper .search input {
  height: 40px;
}

.navbar .upper .search .icon {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fe3c00;
  cursor: pointer;
}

.navbar .upper .search .icon svg {
  width: 32px;
  height: 32px;
}

.navbar .upper .right {
  margin: auto;
  margin-right: 0;
}

.navbar .upper .right .auth {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.navbar .upper .right .login-link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #666666 !important;
  cursor: pointer;
}

.navbar .upper .right .login-link svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.navbar .upper .right .login-link:hover {
  color: #666666 !important;
}

.navbar .upper .right .auth .profile {
  position: relative;
  user-select: none;
}

.navbar .upper .right .auth .profile .account {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.navbar .upper .right .auth .profile .account svg {
  width: 24px;
  height: 24px;
}

.navbar .upper .right .auth .profile .account svg:last-child {
  margin-left: 8px;
}

.navbar .upper .right .auth .profile .auth-links {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 180px;
  display: none;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  background: #ffd700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 9999;
}

.navbar .upper .right .auth .profile .auth-links.active {
  display: flex;
}

.navbar .upper .right .auth .profile .auth-links *:is(a, span, .logout) {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
}

.navbar
  .upper
  .right
  .auth
  .profile
  .auth-links
  *:is(a:hover, span:hover, .logout:hover) {
  color: #ffffff;
}

.navbar .upper .right .auth .link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666666 !important;
  font-weight: 500;
}

.navbar .upper .right .auth .link.compare-icon:before {
  content: "\f079";
  display: inline-block;
  margin-right: 4px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-synthesis: weight style;
  font-stretch: normal;
  font-size-adjust: none;
  font-language-override: normal;
  font-kerning: auto;
  font-feature-settings: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 20px;
  color: #fe3c00;
}

.navbar .upper .right .link:hover {
  color: #666666 !important;
}

.navbar .upper .right .link svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

/* Navbar Lower Area Styles */
.navbar .lower {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
}

.navbar .lower .lower-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.compare-icon {
  color: #fe3c00;
}

.compare-icon:before {
  content: "\f079";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-synthesis: weight style;
  font-stretch: normal;
  font-size-adjust: none;
  font-language-override: normal;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  font-feature-settings: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 26px;
}

.navbar .lower .lower-center {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.navbar .lower .lower-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar .lower .lower-center .blue {
  color: #38afdf;
}

.navbar .lower *:is(a, span) {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  white-space: nowrap;
  color: #666666;
  cursor: pointer;
}

.navbar .lower *:is(a, span) svg {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 1280px) {
  .navbar {
    padding: 16px 0;
  }

  .navbar .upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .navbar .upper .gift-text {
    order: 1;
  }

  .navbar .upper .logo {
    order: 2;
  }
  .navbar .upper .search {
    width: initial;
    order: 3;
  }

  .navbar .upper .right {
    order: 4;
    margin: 0;
  }

  .navbar .upper .right .auth {
    gap: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar .upper {
    flex-direction: column;
    gap: 20px;
  }

  .navbar .upper .right .auth .profile .auth-links {
    left: 0;
    right: initial;
  }

  .navbar .lower {
    flex-direction: column;
    gap: 20px;
  }

  .navbar .lower *:is(.lower-left, .lower-center, .lower-right) {
    margin: 0 auto;
  }

  .navbar .lower .lower-left {
    order: 1;
  }

  .navbar .lower .lower-right {
    order: 2;
  }
}

@media screen and (max-width: 640px) {
  .navbar {
    gap: 24px;
  }

  .navbar a {
    color: #666666 !important;
  }

  .navbar .mobile {
    display: flex;
  }

  .navbar .upper,
  .lower {
    align-items: flex-start !important;
    display: none !important;
    padding: 0 16px !important;
  }

  .navbar .upper .search {
    width: 100%;
  }

  .navbar .upper .right .auth {
    flex-direction: column;
  }

  .navbar .lower {
    gap: 12px;
  }

  .navbar .lower *:is(.lower-left, .lower-center, .lower-right) {
    margin: 0;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }

  .navbar .upper .logo {
    display: none;
  }

  .navbar .upper.active,
  .middle.active,
  .lower.active {
    display: flex !important;
  }
}

/* .router-link-active {
        color: var(--primary) !important;
        border-bottom: 3px solid var(--primary);
        display: inline-block;
    } */

.no-active {
  color: var(--dark) !important;
  border-bottom: none !important;
  display: inline-block;
}

.dropdown-toggle::after {
  display: none;
}
/* .dropdown-toggle::after {
        border: none !important;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f107" !important; */
/* the desired FontAwesome icon */
/* vertical-align: 0; */
/* to center vertically */
/* } */

#autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  font-size: 14px !important;
  border: 1px solid #616161;
  padding: 8px !important;
  width: 520px !important;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.autosuggest__results ul li {
  list-style: none;
  padding: 8px 5px !important;
  cursor: pointer;
}
.autosuggest__results ul li:hover {
  background-color: #f8f9fa !important;
}
.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 520px !important;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 200px;
}
</style>
